import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

const AmericaSvg: React.FC = (): JSX.Element => {
    return (
        <Svg
            width={30}
            height={30}
            viewBox="0 0 64 64"
        >
            <Path d="M48 6.6C43.3 3.7 37.9 2 32 2v4.6h16z" fill="#ed4c5c" />
            <Path d="M32 11.2h21.6C51.9 9.5 50 7.9 48 6.6H32v4.6z" fill="#fff" />
            <Path d="M32 15.8h25.3c-1.1-1.7-2.3-3.2-3.6-4.6H32v4.6z" fill="#ed4c5c" />
            <Path d="M32 20.4h27.7c-.7-1.6-1.5-3.2-2.4-4.6H32v4.6" fill="#fff" />
            <Path d="M32 25h29.2c-.4-1.6-.9-3.1-1.5-4.6H32V25z" fill="#ed4c5c" />
            <Path d="M32 29.7h29.9c-.1-1.6-.4-3.1-.7-4.6H32v4.6" fill="#fff" />
            <Path
                d="M61.9 29.7H32V32H2c0 .8 0 1.5.1 2.3h59.8c.1-.8.1-1.5.1-2.3 0-.8 0-1.6-.1-2.3"
                fill="#ed4c5c"
            />
            <Path
                d="M2.8 38.9h58.4c.4-1.5.6-3 .7-4.6H2.1c.1 1.5.4 3.1.7 4.6"
                fill="#fff"
            />
            <Path
                d="M4.3 43.5h55.4c.6-1.5 1.1-3 1.5-4.6H2.8c.4 1.6.9 3.1 1.5 4.6"
                fill="#ed4c5c"
            />
            <Path
                d="M6.7 48.1h50.6c.9-1.5 1.7-3 2.4-4.6H4.3c.7 1.6 1.5 3.1 2.4 4.6"
                fill="#fff"
            />
            <Path
                d="M10.3 52.7h43.4c1.3-1.4 2.6-3 3.6-4.6H6.7c1 1.7 2.3 3.2 3.6 4.6"
                fill="#ed4c5c"
            />
            <Path
                d="M15.9 57.3h32.2c2.1-1.3 3.9-2.9 5.6-4.6H10.3c1.7 1.8 3.6 3.3 5.6 4.6"
                fill="#fff"
            />
            <Path
                d="M32 62c5.9 0 11.4-1.7 16.1-4.7H15.9c4.7 3 10.2 4.7 16.1 4.7"
                fill="#ed4c5c"
            />
            <Path
                d="M16 6.6c-2.1 1.3-4 2.9-5.7 4.6-1.4 1.4-2.6 3-3.6 4.6-.9 1.5-1.8 3-2.4 4.6-.6 1.5-1.1 3-1.5 4.6-.4 1.5-.6 3-.7 4.6-.1.8-.1 1.6-.1 2.4h30V2c-5.9 0-11.3 1.7-16 4.6"
                fill="#428bc1"
            />
            <G fill="#fff">
                <Path d="m25 3 .5 1.5H27l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zM29 9l.5 1.5H31l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zM21 9l.5 1.5H23l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zM25 15l.5 1.5H27l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zM17 15l.5 1.5H19l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zM9 15l.5 1.5H11l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zM29 21l.5 1.5H31l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zM21 21l.5 1.5H23l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zM13 21l.5 1.5H15l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zM25 27l.5 1.5H27l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zM17 27l.5 1.5H19l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zM9 27l.5 1.5H11l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zM11.8 13l1.2-.9 1.2.9-.5-1.5 1.2-1h-1.5L13 9l-.5 1.5h-1.4l1.2.9-.5 1.6M3.8 25l1.2-.9 1.2.9-.5-1.5 1.2-1H5.5L5 21l-.5 1.5h-1c0 .1-.1.2-.1.3l.8.6-.4 1.6" />
            </G>
        </Svg>
    )
}

export default AmericaSvg    